import React from 'react';
import { Link, Text } from '@mezzoforte/forge';

export function CustomerService() {
  return (
    <Text>
      Ongelmatilanteissa voit olla yhteydessä{' '}
      <Link
        href="https://asiakaspalvelu.huutokaupat.com/support/solutions/articles/80001081969-asiakaspalvelu"
        target="_blank"
        className="notranslate"
      >
        asiakaspalveluumme.
      </Link>
    </Text>
  );
}
